import { Component, OnDestroy, OnInit } from '@angular/core';
import { MaterialTableDataRow, MaterialTableDataRowDisplayMode, MaterialTableTreeNode } from '@app/modules/mounting/models';
import { WarehouseStockModalComponent } from '@app/modules/mounting/containers/tab-secondary/components';
import { TabSecondaryService } from '@app/modules/mounting/services';
import { ToolsService } from '@app/modules/tools/services';
import { ButtonItem } from '@app/shared/components';
import { ToolSetup } from '@app/shared/models';
import { TranslateService } from '@ngx-translate/core';
import { ProductType, ArticleClassification, ScannedIdentificationCodeType, ScanContainerResponse } from 'chronos-core-client';
import { DialogService } from 'primeng/dynamicdialog';
import { Observable, Subject, Subscription } from 'rxjs';
import { ReduceConsumptionModalComponent } from '@app/shared/modals';
import { AppSettingsQuery, DismountingPalletInformation, LogService } from 'chronos-shared';
import { DismountDummyPalletModalComponent } from '@app/shared/components/dismount-dummy-pallet-modal/dismount-dummy-pallet-modal.component';
import * as lodash from 'lodash';
import { ParallelConsumptionModalComponent } from './components/parallel-consumption-modal/parallel-consumption-modal.component';

export interface ArticleDescriptionElement {
  articleName: string;
  productType: any;
  articleClassification: any;
  bomUnitId: string;
  configurationName?: string;
  externalArticleId: string;
  externalConfigurationId?: string;
  grammage?: number;
  id: number;
  inventoryUnitId: string;
  labelAutoPrint: boolean;
  thickness?: number;
  label: string;
  value: number | string;
}
@Component({
  selector: 'app-tab-secondary',
  templateUrl: './tab-secondary.component.html',
  styleUrls: ['./tab-secondary.component.scss']
})
export class TabSecondaryComponent implements OnInit, OnDestroy {
  private scanValidationSubject = new Subject<boolean>();
  public scanValidationChanges$: Observable<boolean> = this.scanValidationSubject.asObservable();
  public secondaryMaterial$: Observable<MaterialTableTreeNode[]>;
  public secondaryMountedMaterial$: Observable<MaterialTableTreeNode[]>;
  public isBillofMaterialDuplicationEnabled = false;
  public readonly productType = ProductType;
  public readonly articleClassification = ArticleClassification;
  public secondaryAlternativeArticles: ArticleDescriptionElement[];
  private subscriptions = new Subscription();
  private readonly RELOAD_INTERVAL = 5000;

  MaterialTableDataRowDisplayMode = MaterialTableDataRowDisplayMode;

  constructor(
    private tabSecondaryService: TabSecondaryService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private toolsService: ToolsService,
    public appSettingsQuery: AppSettingsQuery
  ) {}

  public ngOnInit(): void {
    this.secondaryMaterial$ = this.tabSecondaryService.secondaryMaterial$;
    this.secondaryMountedMaterial$ = this.tabSecondaryService.secondaryMountedMaterial$;

    this.appSettingsQuery.allowBillOfMaterialDuplication$.subscribe((result) => {
      this.isBillofMaterialDuplicationEnabled = result;
    });

    this.loadSecondaryMaterialOverviewData();
    this.loadSecondaryMountedMaterialData();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public openWarehouseStockModal(): void {
    this.openConsumeModal();
  }

  public getDummyToolActions(rowData: MaterialTableDataRow): ButtonItem[] {
    return [
      {
        label: 'MOUNTING.REPLACE',
        command: () => {
          this.replaceDummyTool(rowData);
        },
        primary: true,
        id: rowData.article.externalArticleId
      }
    ];
  }

  private replaceDummyTool(rowData: MaterialTableDataRow): void {
    const tabName = 'Secondary';
    const isDropDownOptionDisabled = true;
    const modal = this.toolsService.replaceDummyTool(
      rowData.mountedMaterialId,
      rowData.containerId,
      tabName,
      isDropDownOptionDisabled,
      rowData.article.id
    );

    this.subscriptions.add(
      modal.onClose.subscribe((materialDataChanged) => {
        if (materialDataChanged) {
          this.tabSecondaryService.renewSecondaryMaterial();
        }
      })
    );
  }

  public getToolActions(rowData: MaterialTableDataRow): ButtonItem[] {
    return [
      {
        label: 'MOUNTING.MOUNT',
        command: () => {
          this.openToolStockModal(rowData);
        },
        primary: true,
        id: rowData.article.externalArticleId
      },
      {
        label: 'MOUNTING.MOUNT_DUMMY',
        command: () => {
          this.createDummyTool(rowData);
        }
      },
      {
        label: 'MOUNTING.SPLIT_BOM_LINES',
        command: () => {
          this.openSplitBillOfMaterialModal(rowData);
        },
        visible: this.isBillofMaterialDuplicationEnabled,
        disabled: rowData.billOfMaterialIdForSplitting === null
      }
    ];
  }

  public openToolStockModal(rowData: MaterialTableDataRow): void {
    const tabName = 'Secondary';
    const isDropDownOptionDisabled = true;
    const modal = this.toolsService.openToolStockModal(rowData.materialBlockId, tabName, rowData.article.id, isDropDownOptionDisabled);

    this.subscriptions.add(
      modal.onClose.subscribe((materialDataChanged) => {
        if (materialDataChanged) {
          this.tabSecondaryService.renewSecondaryMaterial();
        }
      })
    );
  }

  public dismountMaterial(rowData: MaterialTableDataRow): void {
    if (rowData.isVirtualContainer) {
      this.openDummyDismountingModal(rowData);
    } else {
      this.openConsumptionModal(rowData);
    }
  }

  public openDummyDismountingModal(rowData: MaterialTableDataRow): void {
    const modal = this.dialogService.open(DismountDummyPalletModalComponent, {
      header: this.translateService.instant('DISMOUNT_DUMMY_PALLET_FORM.DISMOUNT_DUMMY'),
      data: {
        containerId: rowData.containerId,
        dismountingPalletInformation: {
          article: rowData.article,
          identificationCode: rowData.identificationCode,
          mounted: rowData.mountedQuantity,
          consumed: rowData.consumedQuantity,
          bomUnitFactor: rowData.bomUnitFactor,
          virtualContainerInfo: rowData.virtualContainerInfo,
          mountedMaterialId: rowData.mountedMaterialId,
          reason: rowData.virtualContainerReason
        } as DismountingPalletInformation
      }
    });

    this.subscriptions.add(
      modal.onClose.subscribe((materialDataChanged) => {
        if (materialDataChanged) {
          this.tabSecondaryService.renewSecondaryMaterial();
        }
      })
    );
  }

  public openConsumptionModal(rowData: MaterialTableDataRow): void {
    const modal = this.dialogService.open(ReduceConsumptionModalComponent, {
      header: this.translateService.instant('MOUNTING.DISMOUNT'),
      data: {
        bomUnitFactor: rowData.bomUnitFactor,
        inventoryUnitId: rowData.inventoryUnitId,
        article: rowData.article,
        containerId: rowData.containerId,
        reduceInformation: {
          ...rowData,
          mounted: rowData.mountedQuantity,
          consumed: rowData.consumedQuantity,
          printLabel: rowData.article.labelAutoPrint,
          mountedMaterialId: rowData.mountedMaterialId,
          mountedTo: rowData.mountedTo
        } as DismountingPalletInformation
      }
    });

    this.subscriptions.add(
      modal.onClose.subscribe((materialDataChanged) => {
        if (materialDataChanged) {
          this.tabSecondaryService.renewSecondaryMaterial();
        }
      })
    );
  }

  public scanAndMountTool(identificationCode: string): void {
    this.tabSecondaryService.getScannedSecondaryContainerInfo(identificationCode).subscribe(
      (scanResponse: ScanContainerResponse) => {
        if (this.shouldMountScan(scanResponse)) {
          this.mountTool(scanResponse.scannedValue, scanResponse.containerId);
        }
      },
      () => {
        this.scanValidationSubject.next(false);
      }
    );
  }

  private mountTool(identificationCode: string, containerId: number): void {
    this.toolsService.mountToolForActiveOrder(identificationCode, containerId).subscribe(() => {
      LogService.success('SUCCESS_MESSAGE.MATERIAL_MOUNTED');
      this.tabSecondaryService.renewSecondaryMaterial();
    });
  }

  private openConsumeModal(rowData?: MaterialTableDataRow, isDropDownOptionDisabled?: boolean, scanResponse?: ScanContainerResponse): void {
    const tabName = 'Secondary';
    const modal = this.dialogService.open(WarehouseStockModalComponent, {
      header: this.translateService.instant('MOUNTING.WAREHOUSE_STOCK_TITLE'),
      data: {
        tabName,
        articleForConsumption: rowData?.article,
        filterFromScan: scanResponse,
        suggestedQuantity: rowData?.openQuantity,
        isDropDownOptionDisabled
      }
    });

    this.subscriptions.add(
      modal.onClose.subscribe((materialDataChanged) => {
        if (materialDataChanged) {
          this.tabSecondaryService.renewSecondaryMaterial();
        }
      })
    );
  }

  public createDummyTool(rowData: MaterialTableDataRow): void {
    const toolSetup: Partial<ToolSetup> = {
      articleId: rowData.article.id,
      materialBlockId: rowData.materialBlockId,
      bomUnitId: rowData.article.bomUnitId
    };
    const secondaryArticleOptions = [];
    if (rowData?.alternativeArticles?.length > 0) {
      rowData.alternativeArticles?.forEach((alternativeArticles) => {
        secondaryArticleOptions.push(alternativeArticles);
      });
    }
    secondaryArticleOptions.push(rowData?.article);
    this.secondaryAlternativeArticles = lodash.cloneDeep(secondaryArticleOptions);
    this.secondaryAlternativeArticles?.forEach((x) => {
      x.label = `${x?.externalArticleId} ${x?.externalConfigurationId}`;
      x.value = x?.id;
    });
    const modal = this.toolsService.createDummyTool(toolSetup, rowData.article.classification, this.secondaryAlternativeArticles);
    this.subscriptions.add(
      modal.onClose.subscribe(() => {
        this.tabSecondaryService.renewSecondaryMaterial();
      })
    );
  }

  public openSplitBillOfMaterialModal(rowData: MaterialTableDataRow) {
    this.dialogService.open(ParallelConsumptionModalComponent, {
      header: this.translateService.instant('MOUNTING.PARALLEL_CONSUMPTION'),
      data: {
        billOfMaterialId: rowData.billOfMaterialIdForSplitting
      }
    });
  }

  private loadSecondaryMaterialOverviewData(): void {
    this.subscriptions.add(
      this.tabSecondaryService.getSecondaryMaterialOverviewData().subscribe(() => {
        setTimeout(() => {
          this.loadSecondaryMaterialOverviewData();
        }, this.RELOAD_INTERVAL);
      })
    );
  }

  private loadSecondaryMountedMaterialData(): void {
    this.subscriptions.add(
      this.tabSecondaryService.getSecondaryMountedMaterialData().subscribe(() => {
        setTimeout(() => {
          this.loadSecondaryMountedMaterialData();
        }, this.RELOAD_INTERVAL);
      })
    );
  }

  /*
    Determines between the two mounting possibilities:
    - Mount for material that are rolls, pallets, products (things that can be in principle primary material)
    - MountAndConsume for the rest, especially liquids like inks
   */
  private shouldMountScan(scanResponse: ScanContainerResponse): boolean {
    if (scanResponse.scannedIdentificationCodeType === ScannedIdentificationCodeType.NONE) {
      return false;
    }
    return true;
  }
}
